import rest from '../api/rest'
import _ from 'lodash'
import shortuuid from 'short-uuid'

export const Shopify = {
  async pushProduct(site_id, productTypes, product) {
    console.log("product", product)
    return new Promise(async (resolve, reject) => {

      let productAttributes = []
      let variantImages = []
      var _attributes = [].concat.apply([], product.variants.map(variant => variant.attributes))
      _attributes.forEach(attr => {
        let tmpAttr = productAttributes.find(at => at.slug === attr.slug) || { name: attr.name, slug: attr.slug, terms: [] }
        tmpAttr.terms = _.union([...tmpAttr.terms, attr.option])
        productAttributes = _.uniqBy([...productAttributes, tmpAttr], 'slug')
      })

      if (productTypes.length > 1) {
        let productTypeAttribute = {
          name: "Type",
          terms: productTypes.map(type => type.title)
        }
        productAttributes = [productTypeAttribute, ...productAttributes]
      }

      let shopifyProduct = {
        product: {
          title: product.title,
          handle: product.permalink?product.permalink:null,
          product_type: product.product_type,
          body_html: product.description,
          published_scope: 'global',
          vendor: 'POD',
          tags: product.tags,
          images: product.images.map(image => ({ src: image.url })),
          options: productAttributes.map(attr => ({
            name: attr.name
          })),
          metafields: [
            {
              namespace: 'pod',
              value_type: 'json_string',
              key: 'pod_product_types',
              value: JSON.stringify(productTypes.map(type => type.id.toString())),
              type: "json_string"
            }
          ],
          variants: product.variants.map((variant, i) => {
            let options = {}
            productAttributes.forEach((attr, index) => {
              let existsAttr = variant.attributes.find(at => at.name === attr.name)
              if (existsAttr) {
                options[`option${index + 1}`] = existsAttr.option
              } else {
                if (attr.name === "Type" && productTypes.length > 1) {
                  options[`option${index + 1}`] = productTypes.find(type => type.id === variant.product_type_id).title
                } else {
                  options[`option${index + 1}`] = ""
                }
              }
            })
            let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null
            variantImages.push({ position: i + 1, index: variantImageIndex })
            //console.log('variantImages', variantImages)
            return {
              compare_at_price: variant.retail_cost,
              fulfillment_service: 'oopprint',
              weight: product.weight?product.weight:null,
              weight_unit: product.weight?product.weight_unit:null,
              price: variant.sale_cost,
              sku: `${variant.sku}:${shortuuid.generate()}`,
              ...options,
              metafields: [
                {
                  namespace: 'pod',
                  value_type: 'integer',
                  key: 'pod_product_type_variant_id',
                  value: variant.id,
                  type: "integer"
                }
              ]
            }
          })
        }
      }
      console.log("PushProduct", shopifyProduct)
      rest.request('post', site_id, '/admin/api/2020-01/products.json', shopifyProduct).then(res => {

        //Update variant image
        res.product && res.product.variants.forEach(variant => {
          //console.log('res.product.variants', res.product.variants)
          return new Promise(async (resolve, reject) => {
            let variantImageIndex = _.find(variantImages, { position: variant.position })
            if (variantImageIndex && variantImageIndex.index!==null) {
              await rest.request("Put", site_id, `/admin/api/2020-01/variants/${variant.id}.json`, {
                variant: {
                  id: variant.id,
                  image_id: variantImageIndex.index !== null ? res.product.images[variantImageIndex.index].id : null
                }
              }).then(updateVariant => {
                return updateVariant
              }).catch(err => {
                reject(err)
              })
            }
          })
        });

        resolve(res.product)
      }).catch(err => {
        reject(err)
      })
    })
  },

  async mapProduct(site_id, origin_id, productTypes, variations) {
    //console.log(site_id, origin_id, productTypes, variations)
    //get all variants
    // let existsVariants = await rest.request("GET", site_id, `/admin/api/2020-01/products/${origin_id}/variants.json`, {
    //   product: {
    //     variants: []
    //   }
    // }).then(res => {
    //   return res.variants.map(v => v.id)
    // }).catch(err => {
    //   return Promise.reject({ error: [{ message: 'Could not retrieves product variants' }] })
    // })
    //Delete all variants

    //console.log(existsVariants)
    return Promise.reject("Sorry")
  }
}